export default {
  movie_route: "/en/movies/{slug}",
  serie_route: "/en/series/{slug}",
  movie_route_play: "/en/movies/{slug}/play",
  movie_route_play_trailer: "/en/movies/{slug}/play/trailer",
  serie_route_play: "/en/series/{slug}/play",
  serie_route_play_trailer: "/en/series/{slug}/play/trailer",
  episode_route: "/en/series/{slug}/season-{season}/episode-{episode}",
  episode_route_play:
    "/en/series/{slug}/season-{season}/episode-{episode}/play",
  browse_by_country: "/en/discover/{country}",
  routes: {
    home: "/en/",
    home_logged: "/en/home",
    discover: "/en/discover",
    browse: "/en/browse",
    browse_movies: "/en/browse/movies",
    browse_series: "/en/browse/series",
    browse_by_countrie: "/en/browse-by-country",
    my_list: "/en/my-list",
    search: "/en/search",
    about_us: "/en/about-us",
    privacy_policy: "/en/privacy-policy",
    terms_and_conditions: "/en/terms-and-conditions",
    plans: "/en/plans",
    plans_logged: "/en/plans-logged",
    register: "/en/register",
    login: "/en/login",
    logout: "/en/logout",
    forgot_password: "/en/forgot-password",
    forgot_password_sent: "/en/forgot-password-sent",
    faq: "https://help.flixlatino.com/hc/es/sections/115002202368-FAQ",
    get_the_full_experience: "/en/get-the-full-experience",
    miff: "/en/miff",
    miami: "/en/miami",
    premiosplatino: "/en/premiosplatino",
  },
  trending: {
    landing: "https://flixlatino.com/trends/en/",
    top_movies: "https://flixlatino.com/trends/en/top-movies",
    top_movies_now: "https://flixlatino.com/trends/en/top-movies-right-now",
    top_tv_shows: "https://flixlatino.com/trends/en/top-tv-shows",
    top_tv_shows_now: "https://flixlatino.com/trends/en/top-tv-shows-right-now",
  },
};
