import en from "./en";
import es from "./es";
import { createI18n } from "vue-i18n";

const messages = {
  en: en,
  es: es,
};

export const i18n = createI18n({
  locale: "es",
  fallbackLocale: "es",
  globalInjection: true,
  runtimeOnly: false,
  allowComposition: true, // you need to specify that!
  messages: messages,
});

export default messages;
